<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
	<!-- Table Top -->
	<b-row class="d-flex justify-content-around mt-2 mb-2">
        <b-modal ref="passwordModal" hide-footer title="Wprowadź hasło, aby usunąć">
          <div class="d-block text-center">
            <b-form-input
              type="password"
              v-model="pass"
              placeholder="Hasło"
            />
          </div>
          <b-button class="mt-3" variant="outline-success" block @click="deleteClientModal">Usuń</b-button>
        </b-modal>
        <!-- Search -->
        <b-col
          cols="6"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model.lazy="search"
              class="d-inline-block mr-1"
              placeholder="Szukaj..."
            />

          </div>
        </b-col>
    </b-row>

    <b-table
      :items="items"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak danych klientów"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-client', params: { id: data.item.id } }" v-b-tooltip.hover title="Edycja klienta" >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-if="role_id == 1" variant="primary" class="mr-1" @click="deleteClient(data.item.id)" v-b-tooltip.hover title="Usuwanie klienta">
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button v-if="data.item.filters.length == 0" variant="primary" class="mr-1" :to="{ name: 'apps-filter-client', query: { client_id: data.item.id } }" v-b-tooltip.hover title="Dodaj urządzenie">
              <feather-icon icon="PlusIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
    <b-pagination v-model.lazy="page"
        :total-rows="items_count"
        :per-page="limit" first-number last-number prev-class="prev-item" next-class="next-item">
	  <template v-slot:prev-text>
	    <feather-icon icon="ChevronLeftIcon" size="18"/>
	  </template>
	  <template v-slot:next-text>
	    <feather-icon icon="ChevronRightIcon" size="18"/>
	  </template>
	</b-pagination>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BFormCheckbox, BDropdown, BDropdownItem, BFormSelect, BPagination
} from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
  },
  data() {
    return {
      role_id: localStorage.getItem('id'),
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'firstname',
          label: 'Imię',
          sortable: true,
        },
        {
          key: 'lastname',
          label: 'Nazwisko',
          sortable: true,
        },
        {
          key: 'serviceLocation',
          label: 'Lokalizacja',
          sortable: false,
        },
        {
          key: 'nextServiceSymbol',
          label: 'Rodzaj następnego serwisu',
          sortable: false,
        },
        {
          key: 'lastServiceDate',
          label: 'Ostatni serwis',
          sortable: true,
        },
        {
          key: 'lastServiceAddress',
          label: 'Adres montażu',
          sortable: true,
        },
        {
          key: 'filtersCount',
          label: 'Ilość urządzeń',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'akcje',
        },
      ],
      items: [],
      search: '',
      page: 1,
      limit: 20,
      items_count: 10,
      pass: '',
      clientToRemove: ''
    }
  },
  watch: {
    search: function (value) {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      let data = {
        query: value,
        page: this.page,
        limit: this.limit,
      };
      axios.post(`${process.env.VUE_APP_API_URL}Client/Search`, data, config).then(response => {
        this.items = response.data.items
        this.items_count = response.data.count
        this.items.map((item, index) => {
          item.lp = index+1
          item.serviceLocation = `${item.city} ul. ${item.address}`
          item.filtersCount = item.filters.length
          if(item.filters.length > 0){
            item.nextServiceSymbol = item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].next_service_symbol
            item.lastServiceDate = item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].service_date
            item.lastServiceAddress = `${item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].city} ul. ${item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].address}`
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },
    page: function (value) {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      let data = {
        query: this.search,
        page: value,
        limit: this.limit,
      };
      axios.post(`${process.env.VUE_APP_API_URL}Client/Search`, data, config).then(response => {
        this.items = response.data.items
        this.items_count = response.data.count
        this.items.map((item, index) => {
          item.lp = index+1
          item.serviceLocation = `${item.city} ul. ${item.address}`
          item.filtersCount = item.filters.length
          if(item.filters.length > 0){
            item.nextServiceSymbol = item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].next_service_symbol
            item.lastServiceDate = item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].service_date
            item.lastServiceAddress = `${item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].city} ul. ${item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].address}`
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },
  },
  mounted() {
    this.fetchClients()
  },
  methods: {
    hideModal() {
        this.$refs['passwordModal'].hide()
      },
    deleteClientModal() {
      axios.delete(`${process.env.VUE_APP_API_URL}Client/${this.clientToRemove}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: {
          password: this.pass
        }
      }).then((res) => {
        this.$refs['passwordModal'].hide()
        this.pass = ''
        Swal.fire(
          'Pomyślnie usunięto klienta',
          '',
          'success',
        )
        this.fetchClients()
      }).catch((err) => {
        this.$refs['passwordModal'].hide()
        this.pass = ''
        if(err.response.status == 403){
          Swal.fire(
            'Błędne hasło',
            '',
            'error',
          )
        }else if(err.response.status == 400){
          Swal.fire(
            'Aby usunąć klienta trzeba usunąć także wszystkie jego filtry',
            '',
            'error',
          )
        }
      })
    },
    fetchClients() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      let data = {
        query: "",
        page: this.page,
        limit: this.limit,
      };
      axios.post(`${process.env.VUE_APP_API_URL}Client/Search`, data, config).then(response => {
        this.items = response.data.items
        this.items_count = response.data.count
        this.items.map((item, index) => {
          item.lp = index+1
          item.serviceLocation = `${item.city} ul. ${item.address}`
          item.filtersCount = item.filters.length
          if(item.filters.length > 0){
            item.nextServiceSymbol = item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].next_service_symbol
            item.lastServiceDate = item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].service_date
            item.lastServiceAddress = `${item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].city} ul. ${item.filters.sort((a,b) => {
              return new Date(b.service_date) - new Date(a.service_date)
            })[0].address}`
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },
    deleteClient(id) {
      this.$refs['passwordModal'].show()
      this.clientToRemove = id
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
